import React, { useEffect } from "react";
import "./styles/importanceOfRacce.css";
import { Link, useLocation } from "react-router-dom";
import MetaData from "../MetaData";
// import { FaYoutube } from "react-icons/fa";
import img1 from "../../assets-new/images/blog/podcasting-could-be-the-best-marketing-strategy-for-your-brand/img-1.png"

const BTSCraftinga2Danimationvideowithease = () => {
    const pathname = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const mobileMediaQuery = window.matchMedia("(max-width: 500px)")
    return (
        <>
            <MetaData pageTitle="Podcasting could be the Best Marketing Strategy for your Brand" />
            {/* section 1 */}
            <section className="pageBanner insights-banner-blog BTSCraftinga2Danimationvideowithease">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner_content text-center ">
                                {/* <!--<h4><a href="#">home</a> - Blog</h4>-->
                            <!--<h2>Benefits of using stock footage in videos</h2>--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* section 2 */}
            <section className="commonSection blogPage BTSCraftinga2Danimationvideowithease-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1 className="sec_title">
                                BTS - Crafting a 2D Animation Video with Ease
                            </h1>
                        </div>
                    </div>
                    <section id="blog-post">
                        <article>
                            <div className="article-body">
                                <div className="post-content">
                                    {/* <h2>Why They Matter for Your Business</h2> */}
                                    <p>
                                        Animation brings ideas to life, transforming them into stories that captivate audiences. Here’s a short playbook that explains the process of creating a 2D animation video in brief.
                                    </p>


                                    <h2>1. Concept and Script Development</h2>

                                    <p>
                                        Aligning creative ideas with client expectations.
                                    </p>
                                    <p>
                                        This is where the journey begins. Based on client’s inputs on the purpose of the video, target audience, and core message , expected ‘call to action’, the script writer narrates a concise yet impactful story.
                                    </p>
                                    <p>
                                        Here, the rule of thumb one needs to follow is -  around 115 to 120 words for a one minute video.
                                    </p>
                                    <p>
                                        Client’s active participation, timely inputs and feedback is crucial at this stage to ensure there is alignment in client’s expectation and the very first step to video creation.
                                    </p>

                                    <h2>2. Storyboarding :</h2>

                                    <p>Translating the script into a clear visual sequence.
                                        Once the script is ready, a detailed storyboard outlining the sequence of scenes visually is created by the Graphic Designers. This step acts as a blueprint for the animation.
                                    </p>
                                    {/* <div className="row d-flex justify-content-center mb-3 mt-5">
                                        <div className="col-lg-6">
                                            <iframe width={mobileMediaQuery.matches ? "100%" : "560px"} height={mobileMediaQuery.matches ? "250px" : "315px"} src="https://www.youtube.com/embed/LCUvZJojol0?si=dNV27CbVhXJf4PyE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div> */}

                                    <h2>3. Design and Pre-Production :</h2>

                                    <p>
                                        Balancing creativity with brand identity.
                                        Once the storyboard is established, more nuanced visual elements are developed. This includes character designs, backgrounds, and animatics (rough animations) to preview the flow. Here graphic designers offer options to the client with regards to style preference, colour palette, iconography, typography etc.  Based on the inputs and brand guidelines, more nuanced, detailed graphical storyboard is created.
                                    </p>

                                    <h2>4. Animation Production :</h2>

                                    <p>: Ensuring smooth animation and maintaining deadlines
                                        Here, the designs come to life. Animators create movement, expressions, and transitions. This phase involves meticulous attention to detail to ensure smooth and engaging visuals.
                                    </p>

                                    <h2>5. Sound Design and Integration :</h2>

                                    <p>Audio enhances the animation’s impact. Voiceovers, sound effects, and music are synchronized with visuals to add depth and emotion.</p>

                                    <h2>6. Editing and Post-Production :</h2>

                                    <p>In this final stage, the video is polished. Transitions, color grading, and final effects are refined to ensure a seamless output. Here last-minute changes without compromising quality are addressed.</p>

                                    <p>Each step in the animation process requires careful planning and collaboration. Client’s involvement is essential at key stages to ensure the final product aligns with the vision.</p>
                                    {/* <div className="row d-flex justify-content-center mb-3 mt-5">
                                        <div className="col-lg-6">
                                            <img src={img1} width="100%" alt="" />
                                        </div>
                                    </div> */}

                                    <p>At Orange Videos, we simplify this journey, turning your ideas into visually stunning stories. Let’s create something extraordinary together! Get in touch with us today to bring your video idea to  life.</p>

                                </div>

                                {/* <div id="blog-post-gallery"></div> */}
                            </div>
                            {/* <!-- /#blog-post-gallery --> */}
                        </article>

                        {/* <!-- /#blog-post -->
                <!-- /.col --> */}
                    </section>
                </div>
                {/* <!-- /.row --> */}
            </section>
            {/* <a className="bottom-cta blog-cta" href="https://www.youtube.com/@OrangeVideos" target="_blank" rel="noreferrer">
                <div>
                    <p><FaYoutube /> Check our YouTube channel for more such videos</p>
                </div>
            </a> */}
        </>
    );
};

export default BTSCraftinga2Danimationvideowithease;
