import React, { useEffect } from "react";
import "./styles/importanceOfRacce.css";
import { Link, useLocation } from "react-router-dom";
import MetaData from "../MetaData";
// import { FaYoutube } from "react-icons/fa";
import img1 from "../../assets-new/images/blog/podcasting-could-be-the-best-marketing-strategy-for-your-brand/img-1.png"

const ThePowerofVisualStorytelling = () => {
    const pathname = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const mobileMediaQuery = window.matchMedia("(max-width: 500px)")
    return (
        <>
            <MetaData pageTitle="The Power of Visual Storytelling" />
            {/* section 1 */}
            <section className="pageBanner insights-banner-blog ThePowerofVisualStorytelling">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner_content text-center ">
                                {/* <!--<h4><a href="#">home</a> - Blog</h4>-->
                            <!--<h2>Benefits of using stock footage in videos</h2>--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* section 2 */}
            <section className="commonSection blogPage ThePowerofVisualStorytelling-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1 className="sec_title">
                            The Power of Visual Storytelling: How Ad Films Can Shape Public Perception
                            </h1>
                        </div>
                    </div>
                    <section id="blog-post">
                        <article>
                            <div className="article-body">
                                <div className="post-content">
                                    {/* <h2>Why They Matter for Your Business</h2> */}
                                    <p>
                                    Advertising films, or ad films, are powerful tools in the world of marketing. They combine visual storytelling, sound and emotion to communicate a brand's message effectively. Ad films can captivate audiences, evoke emotions and leave lasting impressions. For brands, this medium offers an opportunity to not just promote products but also to align with causes, values or social issues that resonate with their audience.
                                    </p>
                                    
                                    <h2>How you can create a creative Ad Film for your brand:</h2>
                                    <ol>
                                        <li>
                                        What will the Ad film convey to the audience?
                                        <p>Clearly outline the purpose of your ad film. Is it to raise awareness about a specific issue, promote a product or strengthen brand identity?</p>
                                        </li>
                                        <li>
                                        Know Your Audience
                                        <p>What are their interests, values and concerns? Tailoring your message to the audience increases the film's impact.</p>
                                        </li>
                                        <li>
                                        Craft a Compelling Story
                                        <p>A strong narrative is the backbone of an effective ad film. Your story should resonate with your audience, evoke emotions and reflect your brand’s values.</p>
                                        </li>
                                        <li>
                                        Choose the Right Visual and Audio Elements
                                        <p>The visuals should be engaging and the audio, including background scores or voiceovers, should complement the story. High-quality production enhances credibility and viewer engagement.</p>
                                        </li>
                                        <li>
                                        Highlight the Brand Message Subtly
                                        <p>While the film should convey your brand’s message, it should not feel like a hard sell. Subtlety in branding ensures that the audience focuses on the story and the message rather than just the brand.</p>
                                        </li>
                                    </ol>

                                    <h2>Orange Videos’ Ad Film - "Influence"</h2>

                                    <p>At Orange Videos, we believe in leveraging the power of storytelling to create impactful messages. Our ad film, "Influence," is a testament to this belief. This film tackles the perils of smoking, highlighting how adults smoking in public places inadvertently become role models to impressionable children around them.
"Influence" stands out for its creativity and emotional depth. It not only raises awareness about the harmful effects of smoking but also urges the audience to reflect on their actions and the impact they have on the younger generation.
By creating ad films like "Influence," Orange Videos demonstrates how brands can use storytelling to communicate vital messages, evoke emotional responses  and inspire positive change. We hope to continue using our expertise to help brands craft stories that resonate and make a difference. Click on the link to watch “Influence - A Powerful Anti-Smoking Ad Film” </p>
                                    <div className="row d-flex justify-content-center mb-3 mt-5">
                                        <div>
                                            <iframe width={mobileMediaQuery.matches ? "100%" : "1140px"} height={mobileMediaQuery.matches ? "250px" : "500px"} src="https://www.youtube.com/embed/gNUomN23puE?si=3XThMOEDK2EGBdvD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>

                                </div>

                                {/* <div id="blog-post-gallery"></div> */}
                            </div>
                            {/* <!-- /#blog-post-gallery --> */}
                        </article>

                        {/* <!-- /#blog-post -->
                <!-- /.col --> */}
                    </section>
                </div>
                {/* <!-- /.row --> */}
            </section>
            {/* <a className="bottom-cta blog-cta" href="https://www.youtube.com/@OrangeVideos" target="_blank" rel="noreferrer">
                <div>
                    <p><FaYoutube /> Check our YouTube channel for more such videos</p>
                </div>
            </a> */}
        </>
    );
};

export default ThePowerofVisualStorytelling;
