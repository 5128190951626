import React from 'react'
import dummyImg from "../../assets-new/images/podcast-page-assets/our-work/dummy-for-now.png"
import vid1 from "../../assets-new/videos/podcast-uat/our-work/vid1.mp4"
import vid2 from "../../assets-new/videos/podcast-uat/our-work/vid2.mp4"
import vid3 from "../../assets-new/videos/podcast-uat/our-work/Website_Video_3.mp4"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
// import img1 from "../../assets-new/images/podcast-page-assets/uat-imgs/our-work/img1.jpg"
// import img2 from "../../assets-new/images/podcast-page-assets/uat-imgs/our-work/img2.jpg"
// import img3 from "../../assets-new/images/podcast-page-assets/uat-imgs/our-work/img3.jpg"
import img1 from "../../assets-new/images/podcast-page-assets/uat-imgs/our-work/new/1.jpg"
import img2 from "../../assets-new/images/podcast-page-assets/uat-imgs/our-work/new/2.jpg"
import img3 from "../../assets-new/images/podcast-page-assets/uat-imgs/our-work/new/3.jpg"
import img4 from "../../assets-new/images/podcast-page-assets/uat-imgs/our-work/new/4.jpg"
import img5 from "../../assets-new/images/podcast-page-assets/uat-imgs/our-work/new/5.jpg"
import img6 from "../../assets-new/images/podcast-page-assets/uat-imgs/our-work/new/6.jpg"
import { isIOS } from 'react-device-detect'
import { sanitize } from "dompurify";

const OurWork = () => {

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                onClick={onClick}
                className='custom-arrows right-arr'
            >
                <IoIosArrowForward />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className='custom-arrows left-arr'
                onClick={onClick}
            >
                <IoIosArrowBack />
            </div>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const videoConfig = {
        ALLOWED_TAGS: ["video", "source"],
        ALLOWED_ATTRS: {
            video: ["autoplay", "loop", "muted", "poster"],
            source: ["src", "type"],
        },
    };

    return (
        <div className='gray-bg'>
            <div className='commonSection podcast-our-work container'>
                <div className="text-center">
                    <h2 className='sec_title'>Our Work</h2>
                </div>
                <div className='pod-our-work-slide'>
                    <Slider {...settings}>

                        {/* <div className="pod-our-work-container">
                            <div className='content-container' dangerouslySetInnerHTML={{
                                __html: sanitize(`
                                        <video
                                        playsinline
                                        preload="auto"
                                        muted
                                        autoPlay
                                        loop
                                        src=${isIOS ? "https://ssprojects.online/orangevideos-new-website/assets/videos/podcast-our-work-section/Website_Video_3.mp4" : vid3}
                                    width="100%"
                                        >
                                    <source src=${isIOS ? "https://ssprojects.online/orangevideos-new-website/assets/videos/podcast-our-work-section/Website_Video_3.mp4" : vid3} type="video/mp4" />
                                    </video>
                                    `),
                                videoConfig,
                            }}>
                            </div>
                        </div>

                        <div className="pod-our-work-container">
                            <div className='content-container'>
                                <img src={img1} alt="" width="100%" />
                            </div>
                        </div>

                        <div className="pod-our-work-container">
                            <div className='content-container' dangerouslySetInnerHTML={{
                                __html: sanitize(`
                                            <video
                                            playsinline
                                            preload="auto"
                                            muted
                                            autoPlay
                                            loop
                                            src=${isIOS ? "https://ssprojects.online/orangevideos-new-website/assets/videos/podcast-our-work-section/vid1.mp4" : vid1}
                                        width="100%"
                                            >
                                        <source src=${isIOS ? "https://ssprojects.online/orangevideos-new-website/assets/videos/podcast-our-work-section/vid1.mp4" : vid1} type="video/mp4" />
                                        </video>
                                        `),
                                videoConfig,
                            }}>
                            </div>
                        </div>

                        <div className="pod-our-work-container">
                            <div className='content-container'>
                                <img src={img2} alt="" width="100%" />
                            </div>
                        </div>


                        <div className="pod-our-work-container">
                            <div className='content-container' dangerouslySetInnerHTML={{
                                __html: sanitize(`
                                            <video
                                            playsinline
                                            preload="auto"
                                            muted
                                            autoPlay
                                            loop
                                            src=${isIOS ? "https://ssprojects.online/orangevideos-new-website/assets/videos/podcast-our-work-section/vid2.mp4" : vid2}
                                        width="100%"
                                            >
                                        <source src=${isIOS ? "https://ssprojects.online/orangevideos-new-website/assets/videos/podcast-our-work-section/vid2.mp4" : vid2} type="video/mp4" />
                                        </video>
                                        `),
                                videoConfig,
                            }}>
                            </div>
                        </div>

                        <div className="pod-our-work-container">
                            <div className='content-container'>
                                <img src={img3} alt="" width="100%" />
                            </div>
                        </div> */}
                        <div className="pod-our-work-container">
                            <div className='content-container'>
                                <img src={img1} alt="" width="100%" />
                            </div>
                        </div>
                        <div className="pod-our-work-container">
                            <div className='content-container'>
                                <img src={img2} alt="" width="100%" />
                            </div>
                        </div>
                        <div className="pod-our-work-container">
                            <div className='content-container'>
                                <img src={img3} alt="" width="100%" />
                            </div>
                        </div>
                        <div className="pod-our-work-container">
                            <div className='content-container'>
                                <img src={img4} alt="" width="100%" />
                            </div>
                        </div>
                        <div className="pod-our-work-container">
                            <div className='content-container'>
                                <img src={img5} alt="" width="100%" />
                            </div>
                        </div>
                        <div className="pod-our-work-container">
                            <div className='content-container'>
                                <img src={img6} alt="" width="100%" />
                            </div>
                        </div>



                        {/* <div className="pod-our-work-container">
                            <div className='content-container'>
                                <video src={vid3} autoPlay loop muted preload="auto">
                                    <source src={vid3} type='video/mp4' />
                                </video>
                            </div>
                        </div> */}

                    </Slider>
                    {/* <div className="pod-our-work-container col-lg-3 col-md-3 col-sm-12">
                        <div className='content-container'>
                            <img src={dummyImg} alt="dummy img" />
                            <div className="content">
                                <div className="inner-content">
                                    <h4>All About Marketing</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pod-our-work-container col-lg-3 col-md-3 col-sm-12">
                        <div className='content-container'>
                            <img src={dummyImg} alt="dummy img" />
                            <div className="content">
                                <div className="inner-content">
                                    <h4>Dun & Bradstreet</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pod-our-work-container col-lg-3 col-md-3 col-sm-12">
                        <div className='content-container'>
                            <img src={dummyImg} alt="dummy img" />
                            <div className="content">
                                <div className="inner-content">
                                    <h4>SLB</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pod-our-work-container col-lg-3 col-md-3 col-sm-12">
                        <div className='content-container'>
                            <img src={dummyImg} alt="dummy img" />
                            <div className="content">
                                <div className="inner-content">
                                    <h4>Entropik</h4>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div >
        </div >
    )
}

export default OurWork